.navigator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-image: url("../../assets/images/home/navigator.png");
  background-size: 100% 5rem;
}

.navigator .navigator-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  height: 1.944444rem;
  padding: 0 0.555556rem;
}

.navigator .navigator-head>div {
  width: 33%;
  display: flex;
  align-items: center;
}

.navigator .navigator-head>div>img {
  width: 0.555556rem;
  height: 0.555556rem;
  margin: 0 0.194444rem;
}

.options>div {
  height: 0.888889rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options>div:hover {
  background-color: #106fda;
  color: #fff;
}

.panel-title {
  color: #dcfafa;
  font-size: 0.5rem;
}

.risk-grade-head {
  display: inline-block;
  width: 7.5rem;
}

.risk-grade-head>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.083333rem;
}

.risk-grade-head>div .risk-grade-title {
  color: #dcfafa;
  font-size: 0.611111rem;
}

.report-body {
  padding: 0 0.555556rem 0;
  display: flex;
}

.first-row {
  height: 9.722222rem;
}

.risk-grade-body {
  display: flex;
  padding: 0.555556rem 0 0;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.risk-grade-body::-webkit-scrollbar {
  width: 0.138889rem;
  height: 0.277778rem;
}

.risk-grade-body::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0.138889rem;
  border: 0.027778rem solid #106fda;
}

.risk-grade-body .risk-card-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 3.888889rem;
  height: 5rem;
  background: rgba(194,220,237,.25);
  margin-right: 1.111111rem;
}

.risk-grade-body .risk-card-item>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.risk-grade-body .risk-card-item>div>img {
  width: 0.555556rem;
  height: 0.555556rem;
  margin-right: 0.138889rem;
}

.risk-grade-body .risk-card-item>div>span {
  font-size: 0.833333rem;
}

.risk-grade-body .module-name {
  display: flex;
  justify-content: center;
  margin-right: 1.111111rem;
  margin-top: 0.222222rem;
  padding: 0.138889rem;
  color: #0a1d34;
  font-weight: 600;
}

.risk-grade-body .module-name.level-1 {
  background-color: #f03200;
}

.risk-grade-body .module-name.level-2 {
  background-color: #ff6c00;
}

.risk-grade-body .module-name.level-3 {
  background-color: #fac800;
}

.risk-grade-body .module-name.level-4 {
  background-color: #37fdff;
}

.risk-grade-body .module-name.level-5 {
  background-color: #00fa99;
}

.year-on-year {
  color: #3dd9d9;
  font-size: 0.333333rem;
  margin-top: 0.138889rem;
}

.year-on-year.red {
  color: #f03200;
}