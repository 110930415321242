.chart-container {
  position: relative;
}

.chart-container .center-item {
  position: absolute;
  top: calc(100% - 2.5rem);
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -100%);
}

.chart-container .center-item>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-container .center-item>div>img {
  width: 0.555556rem;
  height: 0.555556rem;
}

.chart-container .center-item>div>span {
  font-size: 0.833333rem;
}

.year-on-year {
  color: #3dd9d9;
  font-size: 0.333333rem;
}

.year-on-year.red {
  color: #f03200;
}

.indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.277778rem;
}

.indicators>.indicator-card {
  display: flex;
  color: #fff;
  height: 1.277778rem;
  width: 100%;
  background: linear-gradient(0deg, #005bc6, #02070b);
  box-shadow: 0 0 1.805556rem 0 rgba(0,115,250,.31);
  border: 0.027778rem solid #106fda;
  margin-bottom: 0.333333rem;
  align-items: center;
  padding: 0 0.555556rem;
  justify-content: space-between;
  font-size: 0.388889rem;
  margin-right: 0.277778rem;
}

.indicators>.indicator-card .red-point {
  display: inline-block;
  width: 0.305556rem;
  height: 0.305556rem;
  border-radius: 0.138889rem;
  background-color: #f03200;
}