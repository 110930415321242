.fotile-table {
  --el-table-tr-bg-color: transparent !important;
  color: #dcfafa;
}

.fotile-table th.el-table__cell {
  background-color: transparent;
}

.fotile-table .el-table__cell {
  border-color: transparent !important;
}

.fotile-table .el-table__row.table-head {
  background: transparent;
  color: #3dd9d9;
}

.fotile-table .el-table__row.table-head:hover {
  background: transparent;
}

.fotile-table .el-table__row.table-body {
  background-color: rgba(194,220,237,.05);
}

.fotile-table .year-on-year {
  color: #3dd9d9;
  font-size: 0.333333rem;
  line-height: 0.666667rem;
}

.fotile-table .year-on-year.icon {
  font-size: 0.555556rem;
}

.fotile-table .year-on-year.red {
  color: #f03200;
}